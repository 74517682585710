<template>
    <div class="player-wrapper" :class="[{'is-overlay':pause}, 'player-wrapper-'+$route.name]">
        <keep-alive>
            <video-player v-if="(isMain || needPreload) && mainVideo && Object.keys(mainVideo).length"
                          ref="videoPlayer"
                          key="'main'" :video="mainVideo" controls
                          :muted="mute" :seek="seek"
                          :player-pause="paused" :autoplay="(!paused && isMain)"
                          @play="[maybeShowHint(), mayHideMenu()]" @exitPlayer="toggleHome"
                          @muted="onMuted"
                          @canplay="onCanplay"
                          @playError="onError"
                          @pause="mayShowMenu" @ended="onEnded"
                          @openMenu="openMenu('OverviewInfo')">
                <template v-slot:overlay="{video}">
                    <section class="section">
                        <div class="video-info">
                            <p class="video-info__heading">You're watching:</p>
                            <h1 v-if="video.title" class="video-info__title">{{
                                    video.title.rendered || video.title
                                }}</h1>
                            <p v-if="!$device.mobile" class="video-info__info" v-html="video.description"></p>
                        </div>
                    </section>
                </template>
            </video-player>
        </keep-alive>

        <keep-alive>
            <video-player key="preview" v-if="isPreview && preview" ref="homePlayer" :video="preview"
                          :class="'player-intro'"
                          :name="'preview'" :player-pause="paused" :autoplay="!paused"
                          :loop="true" :controls="false"></video-player>
        </keep-alive>

        <video-player v-if="isTrailer && activeVideo && Object.keys(activeVideo).length" ref="trailerPlayer"
                      :video="activeVideo" controls
                      :muted="mute" key="'trailer'" :autoplay="!paused" :player-pause="paused" @ended="onEnded"
                      @play="maybeShowHint" @exitPlayer="toggleHome"
                      @openMenu="openMenu('Trailers')"></video-player>

        <transition name="opacity">
            <lottie-animation v-if="hint.show" path="img/smartphone-rotate.json" :speed="0.6"
                              :loop="false" :width="60" :height="60"></lottie-animation>
        </transition>
    </div>
</template>

<script>
import actions from "../modules/fetchAxios";
import store from "../store";
import VideoPlayer from "../components/videoPlayer";
import {EventBus} from "../main";
import c from "../router/constants";

export default {
    name: "Watch",
    components: {VideoPlayer},
    props: {
        id: {type: [Number, String], default: 0},
        video: Object,
        pause: Boolean,
        seek: [String, Number],
        isHome: Boolean
    },
    data() {
        return {
            isPreview: false,
            isMain: false,
            isTrailer: false,
            videoId: this.id,
            activeVideo: this.video,
            loading: true,
            paused: this.pause,
            projectImage: [],
            hint: {
                show: false,
                shown: false,
                time: 2000
            },
        }
    },

    computed: {
        preview() {
            return store.preview
        },
        project() {
            return store.project
        },
        video_id() {
            return store.video_id
        },
        mainVideo() {
            return store.video
        },
        needPreload() {
            return !store.mainReady
        },
        mute() {
            if (this.$device.mobile) {
                return store.mute
            } else {
                return false
            }
        }
    },

    watch: {
        pause(val) {
            this.paused = val
        },

        video_id(val) {
            if (val && this.isMain) {
                this.loadVideo()
            }
        },
        project: {
            handler: function (val) {
                if (val) {
                    this.get_project_image()
                }
            },
            immediate: true
        },

        id: {
            handler: 'init',
            immediate: true
        }

    },

    mounted() {
        // this.init()
    },

    activated() {
        this.paused = this.pause
    },

    deactivated() {
        this.paused = true
    },

    methods: {
        async loadVideo() {
            store.video = await actions.getVideo(this.video_id).finally(() => {
                this.loading = false
            })
        },

        async get_trailer() {
            this.activeVideo = await actions.fetchTrailer(this.videoId)
                .catch((err) => {
                    console.error(err)
                    this.$router.push({
                        name: 'Home'
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        maybeShowHint() {
            if (!this.$device.mobile || this.hint.shown) return;

            if (window.innerWidth < window.innerHeight)
                this.hint.show = true
            this.hint.shown = true
            setTimeout(() => {
                this.hint.show = false
            }, this.hint.time)

        },

        toggleHome() {
            this.$router.push({name: 'Home'})
        },

        openMenu(view) {
            this.$router.push({name: view})
        },

        onCanplay() {
            console.log('can play')
            store.mainReady = true
        },

        onEnded() {
            console.log('Ended!')
            this.openMenu('OverviewInfo')
        },

        onError() {
            console.log('ERROR')
            store.mainReady = false
            this.toggleHome()
        },

        onMuted(volume) {
            if (!volume) {
                store.mute = true
            } else {
                store.mute = false
            }
        },

        init() {
            this.videoId = this.id || this.$route.params.id

            switch (true) {
                case this.videoId < 0 :
                    //show main video
                    this.isMain = true
                    this.isPreview = false
                    this.paused = this.pause
                    if (Object.keys(this.mainVideo).length) {
                        this.loading = false
                    } else {
                        if (this.video_id)
                            this.loadVideo()
                    }
                    break;
                case this.videoId > 0:
                    // show trailer
                    this.isTrailer = true
                    if (!this.mainVideo || this.videoId !== this.mainVideo.id)
                        this.get_trailer()
                    break;
                default:
                    //show background video
                    this.isPreview = true
                    this.isMain = false
            }
        },

        mayShowMenu() {
            EventBus.$emit('showMenuPlayer')
            console.log('showMenuPlayer showMenuPlayer')
        },
        mayHideMenu() {
            EventBus.$emit('hideMenuPlayer')
            console.log('hideMenuPlayer hideMenuPlayer')
        },

        get_project_image() {
            let image
            if (this.project && this.project.gallery) {
                image = this.project.gallery[Math.floor(Math.random() * this.project.gallery.length)];

                if (image) {
                    if (this.$device.mobile && image.mobile) {
                        this.projectImage.push(image.mobile)
                    } else {
                        this.projectImage.push(image.desktop)
                    }
                }
                if (this.projectImage.length > 1) {
                    this.projectImage.shift()
                }

                return this.projectImage
            }

            return this.projectImage
        }
    },

    beforeRouteLeave: (to, from, next) => {
        to.params.trailer = from.params.id
        if (from.name === c.WATCH || from.name === c.INFO) {
            to.params.trailerBack = '1'
        }
        next()
    }
}
</script>

<style scoped>

</style>
